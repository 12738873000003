// src/services/api.js

export const sendMessageToAPI = async ({ messages, model, temperature = 0.7, top_p = 0.7 }) => {
  const API_URL = 'https://api.groq.com/openai/v1/chat/completions'; // Confirm with Groq documentation
  const API_KEY = process.env.REACT_APP_API_KEY;

  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages,
        model,
        temperature,
        max_tokens: 4096,
        top_p,
        stream: false,
        stop: null,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('API Error:', errorData);
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${JSON.stringify(errorData)}`
      );
    }

    const data = await response.json();
    console.log('API Response:', data);
    return data.choices[0].message.content;
  } catch (error) {
    console.error('Error in sendMessageToAPI:', error.message);
    throw error;
  }
};

/**
 * Sends a vision-based prompt to the API with context, image, and accompanying instructions.
 * @param {Object} params - The parameters for the vision prompt.
 * @param {string} params.context - The previous assistant response to provide context.
 * @param {string} params.text - The predefined user prompt.
 * @param {string} params.imageUrl - The Data URL of the concatenated PNG image.
 * @param {number} params.temperature - The sampling temperature.
 * @param {number} params.top_p - The nucleus sampling parameter.
 * @returns {Promise<string>} - A promise that resolves to the API's response content.
 */
export const sendVisionPrompt = async ({
  context,
  text,
  imageUrl,
  temperature = 0.5,
  top_p = 0.5,
}) => {
  const VISION_API_URL = 'https://api.groq.com/openai/v1/chat/completions'; // Confirm if different
  const API_KEY = process.env.REACT_APP_API_KEY;
  const VISION_MODEL = 'llama-3.2-11b-vision-preview';

  try {
    const response = await fetch(VISION_API_URL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: context, // Include previous response as context
              },
              {
                type: 'text',
                text: text, // Predefined user prompt
              },
              {
                type: 'image_url',
                image_url: {
                  url: imageUrl, // Image data URL
                },
              },
            ],
          },
        ],
        model: VISION_MODEL,
        temperature,
        max_tokens: 4096,
        top_p,
        stream: false,
        stop: null,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Vision API Error:', errorData);
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${JSON.stringify(errorData)}`
      );
    }

    const data = await response.json();
    console.log('Vision API Response:', data);
    return data.choices[0].message.content;
  } catch (error) {
    console.error('Error in sendVisionPrompt:', error.message);
    throw error;
  }
};