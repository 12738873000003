// src/App.js

import React, { useState } from 'react';
import ChatHistory from './components/ChatHistory';
import InputArea from './components/InputArea';
import ModelSelection from './components/ModelSelection';
import './App.css';
import logo from './assets/logo.png';

function App() {
  const [chatHistory, setChatHistory] = useState([]);
  const [pdfText, setPdfText] = useState('');
  const [lastImageUrl, setLastImageUrl] = useState(null);
  const [selectedModel, setSelectedModel] = useState('llama-3.2-90b-text-preview');

  const updateChatHistory = (role, content, imageUrl = null) => {
    let messageContent = content;
    if (role === 'user' && imageUrl) {
      messageContent = {
        text: content,
        image: imageUrl,
      };
      setLastImageUrl(imageUrl);
    }

    setChatHistory((prevChatHistory) => [
      ...prevChatHistory,
      { role, content: messageContent },
    ]);
  };

  return (
    <div className="app-container">
      {/* Header containing Logo and Model Selection */}
      <header className="header">
        <div className="logo-container">
          <img src={logo} alt="Illuminant AI Logo" className="logo" />
        </div>
        <ModelSelection selectedModel={selectedModel} setSelectedModel={setSelectedModel} />
      </header>

      {/* Main Content */}
      <div className="main-content">
        <ChatHistory chatHistory={chatHistory} />
        <InputArea
          chatHistory={chatHistory}
          updateChatHistory={updateChatHistory}
          pdfText={pdfText}
          setPdfText={setPdfText}
          lastImageUrl={lastImageUrl}
          setLastImageUrl={setLastImageUrl}
          selectedModel={selectedModel}
        />
      </div>
    </div>
  );
}

export default App;
