// src/components/InputArea.js

import React, { useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import { sendMessageToAPI, sendVisionPrompt } from '../services/api';
import styles from './InputArea.module.css';

// Set up PDF.js worker to use the local worker file
pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.js`;

function InputArea({
  chatHistory,
  updateChatHistory,
  pdfText,
  setPdfText,
  lastImageUrl,
  setLastImageUrl,
  selectedModel,
}) {
  const [userInput, setUserInput] = useState('');
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [pdfContent, setPdfContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStage, setLoadingStage] = useState(''); // New state variable
  const [error, setError] = useState('');
  const [textModelTemp, setTextModelTemp] = useState(0.7);
  const [textModelTopP, setTextModelTopP] = useState(0.7);
  const [visionModelTemp, setVisionModelTemp] = useState(0.5);
  const [visionModelTopP, setVisionModelTopP] = useState(0.5);
  const [suggestions, setSuggestions] = useState([]);

  /**
   * Handles file input changes, processes images and PDFs accordingly.
   * @param {Event} e - The file input change event.
   */
  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setError(''); // Clear previous errors

    if (!selectedFile) return;

    if (selectedFile.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
        setPdfContent('');
        setPdfText('');
        setLastImageUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else if (selectedFile.type === 'application/pdf') {
      try {
        const arrayBuffer = await selectedFile.arrayBuffer();
        const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
        let text = '';
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const content = await page.getTextContent();
          const pageText = content.items
            .sort(
              (a, b) =>
                b.transform[5] - a.transform[5] ||
                a.transform[4] - b.transform[4]
            )
            .map((item) => item.str)
            .join(' ');
          text += pageText + '\n';
        }
        setPdfText(text);
        setPdfContent(text);
        setImagePreviewUrl('');
        setLastImageUrl(null);
      } catch (error) {
        console.error('Error processing PDF:', error);
        setError('Failed to process PDF. Please try another file.');
        setFile(null);
        setPdfText('');
        setPdfContent('');
      }
    }
  };

  /**
   * Converts the uploaded PDF into a single vertically concatenated PNG image.
   * @returns {Promise<string>} - A promise that resolves to the Data URL of the concatenated PNG image.
   */
  const convertPdfToVerticalImage = async () => {
    if (!file || file.type !== 'application/pdf') {
      throw new Error('No PDF file available for conversion.');
    }

    const arrayBuffer = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
    const canvasList = [];

    // Render each page of the PDF to a canvas
    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 2 }); // Adjust scale as needed
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({ canvasContext: context, viewport }).promise;
      canvasList.push(canvas);
    }

    // Calculate total height and max width
    const totalHeight = canvasList.reduce((sum, canvas) => sum + canvas.height, 0);
    const maxWidth = Math.max(...canvasList.map((canvas) => canvas.width));

    // Create a new canvas to concatenate all page canvases vertically
    const finalCanvas = document.createElement('canvas');
    finalCanvas.width = maxWidth;
    finalCanvas.height = totalHeight;
    const finalContext = finalCanvas.getContext('2d');

    let currentY = 0;
    for (const canvas of canvasList) {
      finalContext.drawImage(canvas, 0, currentY, canvas.width, canvas.height);
      currentY += canvas.height;
    }

    // Convert the final canvas to a PNG Data URL
    const pngDataUrl = finalCanvas.toDataURL('image/png');
    return pngDataUrl;
  };

  /**
   * Parses the AI's response to extract suggestions.
   * @param {string} response - The AI's response containing suggestions.
   * @returns {Array<string>} - An array of suggestion strings.
   */
  const parseSuggestions = (response) => {
    const match = response.match(/\[([^\]]+)\]/);
    if (match && match[1]) {
      return match[1]
        .split(',')
        .map(s => s.trim())
        .filter(s => s.length > 0);
    }
    return [];
  };

  /**
   * Handles the click event for suggestion buttons.
   * @param {string} suggestion - The suggestion text to send as a message.
   */
  const handleSuggestionClick = (suggestion) => {
    setUserInput(suggestion);
    setTimeout(handleSendMessage, 20);
  };

  const sendSuggestion = (suggestion) => {
      handleSendMessage();
  };

  /**
   * Handles sending the message.
   */
  const handleSendMessage = async () => {
    setIsLoading(true);
    setError('');
    setLoadingStage('analyzing');

    try {
      // Validate input
      if (!userInput && !file) {
        setError('Please enter a message or upload a file.');
        setIsLoading(false);
        setLoadingStage('');
        return;
      }

      // Prepare messages array starting with system prompt
      const systemPrompt = `do not answer questions about anything not relating to medical information. you may receive a long string of characters that don't make sense. these are converted from a pdf of a medical chart that may be jumbled, but recursively think about what you see and analyze it based on what makes sense. think about it until you have a confidence of 0.95. if you choose to show results or ranges, show both. provide all results. If the user provides text asking about anything NOT medical related, simply respond telling them you are programmed only to talk about medical documents and info. Along with any impressions, you should provide a conclusion section at the bottom of your response explaining what these results mean for them.`;
      const messages = [
        { role: 'system', content: systemPrompt },
        // Include previous chat history
        ...chatHistory.map(chat => ({ role: chat.role, content: chat.content })),
      ];

      // Append the new user message or construct a prompt with PDF content
      if (pdfText) {
        const userPrompt = `You have access to the following document content:\n\n${pdfText}\n\nUser question: ${userInput || 'Please analyze the document.'}`;
        messages.push({ role: 'user', content: userPrompt });
      } else if (userInput) {
        messages.push({ role: 'user', content: userInput });
      }

      // Log messages for debugging
      console.log('Messages sent to the initial model:', messages);

      // Send to initial text-based model
      let assistantResponse = await sendMessageToAPI({
        messages,
        model: selectedModel,
        temperature: textModelTemp,
        top_p: textModelTopP,
      });
      console.log('Received response from text-based model:', assistantResponse);

      // If a PDF was uploaded, proceed to send data to the vision model
      if (file && file.type === 'application/pdf') {
        setLoadingStage('verifying'); // Update loading stage to 'verifying information'

        // Convert PDF to vertical image
        const concatenatedPng = await convertPdfToVerticalImage();
        console.log('PDF converted to image.');

        // Prepare data for vision model
        const visionUserPromptText = `Please repeat your last response word for word. It was an analysis of this medical chart. Keep the exact same wording and format but verify the values are correct and in the right spot, modify any inaccuracies. But do not add any new information or describe what you've done. Keep it the exact same besides corrections.`;

        // Send to vision model
        assistantResponse = await sendVisionPrompt({
          context: assistantResponse, // Previous response as context
          text: visionUserPromptText, // Vision model user prompt
          imageUrl: concatenatedPng, // The combined PDF image
          temperature: visionModelTemp,
          top_p: visionModelTopP,
        });
        console.log('Received response from vision model:', assistantResponse);
      }

      // Now that we have the final assistant response, update the chat history
      updateChatHistory('user', userInput || (file ? `[${file.type.includes('pdf') ? 'PDF' : 'Image'} Uploaded]` : ''));

      updateChatHistory('assistant', assistantResponse);

      // Send hidden message to get suggestions
      const suggestionPrompt = "Based on this information. Give me 3 very short comma separated phrases, each 6 words or less, with relevant suggestions for what I could ask about next based on the last response enclosed in brackets like this: [suggestion 1, suggestion 2, suggestion 3]\n\nDo not include any other words or information besides this.";

      // Prepare messages for suggestion request
      const suggestionMessages = [
        { role: 'system', content: systemPrompt },
        // Include updated chat history including the latest exchange
        ...[...chatHistory, { role: 'assistant', content: assistantResponse }].map(chat => ({ role: chat.role, content: chat.content })),
        { role: 'user', content: suggestionPrompt },
      ];

      // Send to AI to get suggestions
      const suggestionResponse = await sendMessageToAPI({
        messages: suggestionMessages,
        model: selectedModel,
        temperature: textModelTemp,
        top_p: textModelTopP,
      });

      // Parse the suggestions
      const extractedSuggestions = parseSuggestions(suggestionResponse);
      setSuggestions(extractedSuggestions);

      // Clear input fields
      setUserInput('');
      setImagePreviewUrl('');
      setPdfContent('');
      setPdfText('');
      setLastImageUrl(null);
      setFile(null);
    } catch (error) {
      console.error('Error in handleSendMessage:', error);
      setError('An error occurred. Please try again.');
      updateChatHistory('assistant', 'Error: ' + error.message);
    } finally {
      setIsLoading(false);
      setLoadingStage('');
    }
  };

  return (
    <div className={styles.inputContainer}>
      <div className={styles.formGroup}>
        <label htmlFor="fileInput" className={styles.label}>
          Upload Image or PDF:
        </label>
        <input
          type="file"
          accept="image/*,.pdf"
          onChange={handleFileChange}
          id="fileInput"
          className={styles.fileInput}
          aria-describedby="fileHelp"
        />
        <small id="fileHelp" className={styles.smallText}>
          Supported formats: JPG, PNG, PDF
        </small>
      </div>

      {error && <div className={styles.error}>{error}</div>}

      {pdfContent && (
        <div id="pdfContent" className={styles.pdfContent}>
          <pre className={styles.pre}>{pdfContent}</pre>
        </div>
      )}

      {imagePreviewUrl && (
        <div className={styles.imagePreviewContainer}>
          <img
            id="imagePreview"
            src={imagePreviewUrl}
            alt="Preview"
            className={styles.imagePreview}
          />
        </div>
      )}

      <div className={styles.formGroup}>
        <label htmlFor="userInput" className={styles.label}>
          Your Message:
        </label>
        <textarea
          id="userInput"
          rows="4"
          className={styles.textarea}
          placeholder="Enter your message here (or leave blank for automatic analysis)"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
        />
      </div>

      <button
        onClick={handleSendMessage}
        className={styles.sendButton}
        disabled={isLoading}
        aria-busy={isLoading}
        aria-label="Send Message"
      >
        {isLoading ? (
          loadingStage === 'analyzing' ? (
            'Analyzing...'
          ) : loadingStage === 'verifying' ? (
            'Verifying information'
          ) : (
            'Sending...'
          )
        ) : (
          'Send'
        )}
      </button>

      {/* Temperature and Top_p inputs */}
      <div className={styles.parametersContainer}>
        {/* Text Model Parameters */}
        <div className={styles.parameterGroup}>
          <label className={styles.parameterLabel}>Text Model:</label>
          <div className={styles.parameterInputs}>
            <div className={styles.inputPair}>
              <label className={styles.parameterInputLabel}>Temperature:</label>
              <input
                type="number"
                step="0.1"
                min="0"
                max="1"
                value={textModelTemp}
                onChange={(e) => setTextModelTemp(parseFloat(e.target.value))}
                className={styles.parameterInput}
              />
            </div>
            <div className={styles.inputPair}>
              <label className={styles.parameterInputLabel}>Top_p:</label>
              <input
                type="number"
                step="0.1"
                min="0"
                max="1"
                value={textModelTopP}
                onChange={(e) => setTextModelTopP(parseFloat(e.target.value))}
                className={styles.parameterInput}
              />
            </div>
          </div>
        </div>

        {/* Vision Model Parameters */}
        <div className={styles.parameterGroup}>
          <label className={styles.parameterLabel}>Vision Model:</label>
          <div className={styles.parameterInputs}>
            <div className={styles.inputPair}>
              <label className={styles.parameterInputLabel}>Temperature:</label>
              <input
                type="number"
                step="0.1"
                min="0"
                max="1"
                value={visionModelTemp}
                onChange={(e) => setVisionModelTemp(parseFloat(e.target.value))}
                className={styles.parameterInput}
              />
            </div>
            <div className={styles.inputPair}>
              <label className={styles.parameterInputLabel}>Top_p:</label>
              <input
                type="number"
                step="0.1"
                min="0"
                max="1"
                value={visionModelTopP}
                onChange={(e) => setVisionModelTopP(parseFloat(e.target.value))}
                className={styles.parameterInput}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Suggestions Section */}
      {suggestions.length > 0 && (
        <div className={styles.suggestionsContainer}>
          <p className={styles.suggestionsTitle}>You might ask next:</p>
          <div className={styles.suggestionsButtons}>
            {suggestions.map((suggestion, index) => (
              <button
                key={index}
                className={styles.suggestionButton}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default InputArea;