// src/components/ChatHistory.js

import React, { useEffect, useRef } from 'react';
import { marked } from 'marked';
import './ChatHistory.css';

function ChatHistory({ chatHistory }) {
  const chatEndRef = useRef(null);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  return (
    <div id="chatHistory" className="border rounded p-3 mb-4 bg-white">
      {chatHistory.map((msg, index) => (
        <div
          key={index}
          className={`d-flex mb-3 ${msg.role === 'user' ? 'justify-content-end' : 'justify-content-start'}`}
        >
          <div className={`message ${msg.role}-message p-2 rounded`}>
            {typeof msg.content === 'object' && msg.content.image ? (
              <>
                <img src={msg.content.image} className="message-image img-fluid mb-2" alt="Uploaded" />
                <div
                  dangerouslySetInnerHTML={{ __html: marked.parse(msg.content.text || '') }}
                />
              </>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: marked.parse(msg.content) }} />
            )}
          </div>
        </div>
      ))}
      <div ref={chatEndRef} />
    </div>
  );
}

export default ChatHistory;
