// src/components/ModelSelection.js

import React from 'react';

function ModelSelection({ selectedModel, setSelectedModel }) {
  const models = [
    'gemma-7b-it',
    'gemma2-9b-it',
    'llama3-groq-70b-8192-tool-use-preview',
    'llama3-groq-8b-8192-tool-use-preview',
    'llama-3.1-70b-versatile',
    'llama-3.1-8b-instant',
    'llama-3.2-11b-text-preview',
    'llama-3.2-1b-preview',
    'llama-3.2-3b-preview',
    'llama-3.2-90b-text-preview',
    'llama3-70b-8192',
    'llama3-8b-8192',
    'mixtral-8x7b-32768',
  ];

  return (
    <div className="form-group text-center">
      <label htmlFor="modelSelect" className="font-weight-bold mr-2">
        Select Model:
      </label>
      <select
        id="modelSelect"
        className="form-control d-inline-block w-auto"
        value={selectedModel}
        onChange={(e) => setSelectedModel(e.target.value)}
      >
        {models.map((model) => (
          <option key={model} value={model}>
            {model}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ModelSelection;
